/*! angularjs-slider - v5.4.1 -
 (c) Rafal Zajac <rzajac@gmail.com>, Valentin Hervieu <valentin@hervieu.me>, Jussi Saarivirta <jusasi@gmail.com>, Angelin Sirbu <angelin.sirbu@gmail.com> -
 https://github.com/angular-slider/angularjs-slider -
 2016-07-17 */
.rzslider {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 4px;
    margin: 35px 0 15px 0;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.rzslider.with-legend {
    margin-bottom: 40px;
}

.rzslider[disabled] {
    cursor: not-allowed;
}

.rzslider[disabled] .rz-pointer {
    cursor: not-allowed;
    background-color: #d8e0f3;
}

.rzslider span {
    position: absolute;
    display: inline-block;
    white-space: nowrap;
}

.rzslider .rz-base {
    width: 100%;
    height: 100%;
    padding: 0;
}

.rzslider .rz-bar-wrapper {
    left: 0;
    z-index: 1;
    width: 100%;
    height: 32px;
    padding-top: 16px;
    margin-top: -16px;
    box-sizing: border-box;
}

.rzslider .rz-bar-wrapper.rz-draggable {
    cursor: move;
}

.rzslider .rz-bar {
    left: 0;
    z-index: 1;
    width: 100%;
    height: 4px;
    background: #d8e0f3;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}

.rzslider .rz-bar.rz-selection {
    z-index: 2;
    background: #0db9f0;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}

.rzslider .rz-pointer {
    top: -14px;
    z-index: 3;
    width: 32px;
    height: 32px;
    cursor: pointer;
    background-color: #0db9f0;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
}

.rzslider .rz-pointer:after {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 8px;
    height: 8px;
    background: #ffffff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    content: '';
}

.rzslider .rz-pointer:hover:after {
    background-color: #ffffff;
}

.rzslider .rz-pointer.rz-active {
    z-index: 4;
}

.rzslider .rz-pointer.rz-active:after {
    background-color: #451aff;
}

.rzslider .rz-bubble {
    bottom: 16px;
    padding: 1px 3px;
    color: #55637d;
    cursor: default;
}

.rzslider .rz-bubble.rz-selection {
    top: 16px;
}

.rzslider .rz-bubble.rz-limit {
    color: #55637d;
}

.rzslider .rz-ticks {
    position: absolute;
    top: -3px;
    left: 0;
    z-index: 1;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 0;
    padding: 0 11px;
    margin: 0;
    list-style: none;
    box-sizing: border-box;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.rzslider .rz-ticks .rz-tick {
    width: 10px;
    height: 10px;
    text-align: center;
    cursor: pointer;
    background: #d8e0f3;
    border-radius: 50%;
}

.rzslider .rz-ticks .rz-tick.rz-selected {
    background: #0db9f0;
}

.rzslider .rz-ticks .rz-tick .rz-tick-value {
    position: absolute;
    top: -30px;
    transform: translate(-50%, 0);
}

.rzslider .rz-ticks .rz-tick .rz-tick-legend {
    position: absolute;
    top: 24px;
    max-width: 50px;
    white-space: normal;
    transform: translate(-50%, 0);
}

.rzslider .rz-ticks.rz-ticks-values-under .rz-tick-value {
    top: initial;
    bottom: -40px;
}

.rzslider.rz-vertical {
    position: relative;
    width: 4px;
    height: 100%;
    padding: 0;
    margin: 0 20px;
    vertical-align: baseline;
}

.rzslider.rz-vertical .rz-base {
    width: 100%;
    height: 100%;
    padding: 0;
}

.rzslider.rz-vertical .rz-bar-wrapper {
    top: auto;
    left: 0;
    width: 32px;
    height: 100%;
    padding: 0 0 0 16px;
    margin: 0 0 0 -16px;
}

.rzslider.rz-vertical .rz-bar {
    bottom: 0;
    left: auto;
    width: 4px;
    height: 100%;
}

.rzslider.rz-vertical .rz-pointer {
    top: auto;
    bottom: 0;
    left: -14px !important;
}

.rzslider.rz-vertical .rz-bubble {
    bottom: 0;
    left: 16px !important;
    margin-left: 3px;
}

.rzslider.rz-vertical .rz-bubble.rz-selection {
    top: auto;
    left: 16px !important;
}

.rzslider.rz-vertical .rz-ticks {
    top: 0;
    left: -3px;
    z-index: 1;
    width: 0;
    height: 100%;
    padding: 11px 0;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
}

.rzslider.rz-vertical .rz-ticks .rz-tick {
    vertical-align: middle;
}

.rzslider.rz-vertical .rz-ticks .rz-tick .rz-tick-value {
    top: initial;
    left: 22px;
    transform: translate(0, -28%);
}

.rzslider.rz-vertical .rz-ticks .rz-tick .rz-tick-legend {
    top: initial;
    right: 24px;
    max-width: none;
    white-space: nowrap;
    transform: translate(0, -28%);
}

.rzslider.rz-vertical .rz-ticks.rz-ticks-values-under .rz-tick-value {
    right: 12px;
    bottom: initial;
    left: initial;
}