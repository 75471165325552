/* Switch styles generated on https://proto.io/freebies/onoff/ */
.onoffswitch {
    position: relative; width: 85px;
    -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block; overflow: hidden; cursor: pointer;
    border: 2px solid #999999; border-radius: 20px;
}
.onoffswitch-inner {
    display: block; width: 200%; margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before, .onoffswitch-inner:after {
    display: block; float: left; width: 50%; height: 32px; padding: 0; line-height: 32px;
    font-size: 15px; color: white; font-family: Trebuchet, Arial, sans-serif; font-weight: bold;
    box-sizing: border-box;
}
.onoffswitch-inner:before {
    content: "ON";
    padding-left: 12px;
    background-color: #FE9107; color: #FFFFFF;
}
.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 12px;
    background-color: #979797; color: #FFFFFF;
    text-align: right;
}
.onoffswitch-switch {
    display: block; width: 26px; margin: 3px;
    background: #FFFFFF;
    position: absolute; top: 0; bottom: 0;
    right: 53px;
    border: 2px solid #999999; border-radius: 20px;
    transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px;
}

/* Different version, shorter and wider for subscribe switches */
.subscribe-switch .onoffswitch {
    width: 144px;
}
.subscribe-switch .onoffswitch-inner:before, .subscribe-switch .onoffswitch-inner:after {
    height: 28px;
    line-height: 28px;
    font-size: 16px;
    text-indent: 11px;
}
.subscribe-switch .onoffswitch-inner:before {
    content: "Subscribed";
}
.subscribe-switch .onoffswitch-inner:after {
    content: "Unsubscribed";
}
.subscribe-switch .onoffswitch-switch {
    width: 22px;
    right: 115px;
}